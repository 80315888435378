/* eslint no-console: 0 */

import UserPortal from '../user_portal.svelte'

function initializeComponent(target) {
  // Allow an app to be initialized only once
  if(target.dataset["isLoaded"] != "true") {
    target.dataset["isLoaded"] = true;
    new UserPortal({
      target: target,
      props: {
        data: target.dataset
      }
    });
  }
};

function initializeAll(selector) {
  if(!selector) {
    selector = "user-portal";
  }
  let targets = document.getElementsByClassName(selector);
  Array.from(targets).forEach(function(target, index, array) {
    initializeComponent(target);
  });
}

document.addEventListener("DOMContentLoaded", () => {
  window.initializeComponent = initializeComponent;
  window.initializeAll = initializeAll;
  initializeAll();
});
