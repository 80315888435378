<script>
  import { STATES } from './lib/store.js';

  export let email, state;
</script>

<span class="account-exists-container">
  <p>
    An account already exists for<br>
    <span class="account-exists-email">{ $email }</span>!<br>
    Would you like to log in?
  </p>
  <p>
    <button on:click={() => state.set(STATES.SIGN_IN)} class="button">Log In</button>
  </p>
  <p>
    <button on:click={() => state.set(STATES.SIGN_UP)} class="button-text-link">No thanks, go back to sign up</button>
  </p>
</span>
