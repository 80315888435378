<script>
  import { onMount } from 'svelte';
  import { getQueryStringValue, postRequest, redirectToWux } from "./lib/shared.js";
  import { STATES } from './lib/store.js';

  import Terms from './terms.svelte';
  
  export let appId,
             activatorApp,
             bundleIdentifier,
             email,
             firstName,
             code1,
             code2,
             isModal,
             password,
             referrerTag,
             state;

  let activationBlocks,
      activationBlockLength,
      activationPlaceholder,
      activators,
      activatorValues = [],
      errorMessage = "",
      failedActivation,
      failedActivationSignUpData,
      signInUrl = process.env.MARKETING_SIGN_IN_URL,
      signUpUrl = process.env.WEB_API_SIGN_UP_METHOD;

  if (activatorApp) {
    activationBlocks = activatorApp.activation_blocks;
    activationBlockLength = activatorApp.activation_block_length;

    activators = Array.from(Array(activationBlocks),(x,i)=>i)
    activationPlaceholder = Array.from(Array(activationBlockLength), x => '0').join('');
  }

  function signUp(user, signUpBody) {
    postRequest(signUpUrl, signUpBody, bundleIdentifier)
    .then((response) => response.json())
    .then((data) => {
      if (data.user) {
        let signInBody = {
          redirect: getQueryStringValue('redirect'),
          signUp: true,
          user: { ...user, app_id: appId },
        }

        let activationResults = data.activation_results;

        // GTM event
        if (window.dl) {
          window.dl.push({
            "event": "TriggerGaEvent",
            "category": data.user.bundle_identifier,
            "action": "Sign Up",
            "label": "Craft",
            "value": "Native",
            "eventCallback": () => afterSignUp(signInBody, activationResults),
            "eventTimeout" : 2000
          });
        } else {
          afterSignUp(signInBody, activationResults);
        }
      } else if (data.errors) {
        const emailErrors = data.errors.email;
        if (emailErrors && emailErrors.includes('has already been taken')) {
          state.set(STATES.EXISTING_ACCOUNT);
        }
        const keyNames = {
          first_name: 'First name',
          email: 'Email',
          password: 'Password'
        }
        errorMessage = Object.keys(data.errors).map(key => `${keyNames[key]} ${data.errors[key][0]}`)
      }
    }).catch((error) => {
      errorMessage = 'An unexpected error occurred';
      console.log(error);
    })
  }

  function afterSignUp(body, activationResults) {
    failedActivation = activationResults && activationResults.some(r => !r.status);
    if (failedActivation) {
      failedActivationSignUpData = body;
    } else {
      signIn(body);
    }
  }

  function signIn(body) {
    postRequest(signInUrl, body, bundleIdentifier)
    .then((response) => response.json())
    .then((data) => {
        if (data.success) {
          redirectToWux(data);    
        } else {
          errorMessage = "Invalid Username or Password."
          console.log("Error Logging In");
        }
      })
    .catch((error) => {
      console.log("Error in Sign In Callback")
    });
  }

  function handleSignUpSubmission() {
    let params = window.location.search
    let user = {
      email: $email,
      first_name: $firstName,
      password: $password,
      questionnaire_id: getQueryStringValue('questionnaire_id'),
      questionnaire_states: getQueryStringValue('questionnaire_states'),
    }
    console.log("Submitting sign up params: ", params);

    let data = {
      craft: true,
      user,
      sc: getQueryStringValue('sc') || $referrerTag,
      params: params,
    }

    if (activatorApp) {
      let enteredCode = activatorValues.map(input => input.value).join('-');
      if (enteredCode.replace(/-/g, '').length > 0) {
        data['activation_codes'] = {
          [activatorApp.id]: { code: enteredCode }
        }
      }
    }

    signUp(user, data);
  }

  function triggerTab(event, index) {
    const isFull = event.target.value.length >= activationBlockLength;
    const notLastBlock = index < (activationBlocks - 1);
    if (isFull && notLastBlock) {
      // first sibling is dash element, then next input
      event.target.nextElementSibling.nextElementSibling.focus();
    }
  };

  onMount(async () => {
    password.set("");
    errorMessage = "";
    if($code1) {
      activatorValues[0].value = $code1;
    }
    if($code2) {
      activatorValues[1].value = $code2;
    }
  });

</script>
<style>
</style>

{#if failedActivation}
  <div class="sign-in-container activation-code-invalid">
    <h4>You've Signed Up Successfully!</h4>
    <p>* However, your activation code was invalid. Don't worry, you can try to enter your activation code again inside of the app.</p>
    <button on:click={() => signIn(failedActivationSignUpData)} class="hero-button">Launch App</button>
  </div>
{:else}
  <form class="hlt_marketing_sign_up">
    {#if errorMessage != ""}
      <div class="sign_up_errors" style="display: block;">{errorMessage}</div>
    {/if}
    <p>
      <label class="first_name_label">First Name</label>
      <input type="text" name="first_name" placeholder="First Name" class="first_name_input" bind:value={$firstName}>
    </p>
    <p>
      <label class="email_label">Email</label>
      <input type="text" name="email" placeholder="Email" class="email_input" bind:value={$email}>
    </p>
    <p>
      <label class="password_label">Password</label>
      <input type="password" name="password" placeholder="Password (min 8 characters)" class="password_input" bind:value={$password}>
    <p/>
    {#if activatorApp}
      <p class="marketing-api-activation-codes">
      <label for="activator_id">Activation Code <span class="marketing-api-optional-tag">optional</span></label>
        {#each activators as activator, idx}
          <input type="text" id={`code${idx}`} name={`code${idx}`} placeholder={activationPlaceholder} bind:this={activatorValues[idx]} on:keyup={(event) => triggerTab(event, idx)} />
          {#if idx + 1 < activators.length }
            <span class="activation-spacer"><span class="dash">-</span></span>
          {/if}
        {/each}
      </p>
    {/if}
    <p>
      <input type="submit" value="Sign up" on:click|preventDefault={handleSignUpSubmission}>
    </p>
  </form>
{/if}
<Terms isModal={isModal} />
