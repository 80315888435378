function getQueryStringValue (key) {
  return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
}

function isValueTrue(value) {
  // We can expect multiple true values passed from the query string, this validates all of them
  return [1, '1', true, 'true', 't'].includes(value);
}

function redirectToWux(data) {
  let location = data.redirect_to;
    try {
      let tracker = ga.getAll()[0];
      let linker = new window.gaplugins.Linker(tracker);
      let decorated = linker.decorate(location);
      location = decorated;
    } catch(e) {
      console.log(e);
    }
    window.location = location; 
}

function postRequest(url, body, bi) {
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-HLTBundleIdentifier": bi
    },
    body: JSON.stringify(body)
  })
}
  

module.exports = {
  getQueryStringValue,
  isValueTrue,
  redirectToWux,
  postRequest
}
