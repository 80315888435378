<script>
  import { onMount } from 'svelte';
  import { postRequest } from "./lib/shared.js";
  import { STATES } from './lib/store.js';

  export let bundleIdentifier, email, state;

  let errorMessage = "";

  function forgotPassword() {
    errorMessage = "";
    let params = {
      user: {
        email: $email,
      }
    };

    postRequest(process.env.WEB_PASSWORD_RESET_URL, params, bundleIdentifier)
    .then((response) => {
      console.log(response)
      if(response.ok) {
        state.set(STATES.FORGOT_PASSWORD_SUCCESS);
      } else {
        errorMessage = "There is no account for that email.";
      }
    });
  }

  onMount(async () => {
    errorMessage = "";
  });
</script>

<style>
</style>

{#if $state === STATES.FORGOT_PASSWORD}
  <span class="forgot-password-container">
    <form class="forgot-password-form">
      {#if errorMessage != ""}
        <div class="errors">{errorMessage}</div>
      {/if}
      <p class="email-fields">
        <label class="email-label">Email</label>
        <input type="text" name="email" placeholder="Email" class="email-input" bind:value={$email}>
      </p>
      <p class="back-to-sign-in-link-container">
        <a href="#/" class="back-to-sign-in-link" on:click|preventDefault={() => state.set(STATES.SIGN_IN)}>Go back to log in</a>
      </p>
      <p class="submit-forgot-password-fields">
        <input class="submit-forgot-password" type="submit" value="Request Password Reset" on:click|preventDefault={forgotPassword} />
      </p>
    </form>
  </span>
{/if}
{#if $state == STATES.FORGOT_PASSWORD_SUCCESS}
  <span class="forgot-password-success-container">
    <p class="forgot-password-success-message">We've sent a password reset email to {$email}</p>
      <p class="back-to-sign-in-link-container">
        <a href="#/" class="back-to-sign-in-link" on:click|preventDefault={() => state.set(STATES.SIGN_IN)} >Go back to log in</a>
      </p>
  </span>
{/if}
