import { writable } from 'svelte/store';

export function initStore() {
  const email = writable("");
  const password = writable("");
  const referrerTag = writable("");
  const state = writable("");
  const name = writable("");
  const code1 = writable("");
  const code2 = writable("");

  return {
    name,
    code1,
    code2,
    email,
    password,
    referrerTag,
    state,
  }
}

export const STATES = {
  EXISTING_ACCOUNT: 'existing-account',
  FORGOT_PASSWORD: 'forgot-password',
  FORGOT_PASSWORD_SUCCESS: "forgot-password-success",
  SIGN_IN: 'sign-in',
  SIGN_UP: 'sign-up'
}
