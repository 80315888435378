<script>
  import { onMount } from 'svelte';
  import { getQueryStringValue, postRequest, redirectToWux } from "./lib/shared.js";
  import { STATES } from './lib/store.js';
  import Terms from './terms.svelte';
  
  export let appId,
    bundleIdentifier,
    email,
    isModal,
    password,
    state;

  let enteredIncorrectPassword;
  let errorMessage = '';
  let signInUrl = process.env.MARKETING_SIGN_IN_URL;

  function resetErrors() {
    enteredIncorrectPassword = false;
    errorMessage = "";
  }

  function signIn() {
    resetErrors();
    let params = window.location.search;
    let data = {
      user: {
        email: $email,
        password: $password,
        app_id: appId,
        redirect: getQueryStringValue('redirect'),
        sc: getQueryStringValue('sc')
      },
      redirect: getQueryStringValue('redirect'),
      sc: getQueryStringValue('sc'),
      params: params,
    };
    postRequest(signInUrl, data, bundleIdentifier)
    .then((response) => {
      return response.json();
    }).then((data) => {
      if (data.success) {
        redirectToWux(data);
      } else {
        if (data.exists) {
          enteredIncorrectPassword = true;
        } else {
          errorMessage = "Invalid Email/Password."
        }
      }
    });
  }

  onMount(async () => {
    password.set("");
    resetErrors();
  });
</script>

<style>
</style>

<span class="sign-in-container">
  <form class="sign-in-form">
    {#if errorMessage != ""}
      <div class="errors">{errorMessage}</div>
    {/if}
    <p class="email-fields">
      <label for="email" class="email-label">Email</label>
      <input type="text" name="email" id="email" placeholder="Email" class="email-input" bind:value={$email}>
    </p>
    <p class:field-error={enteredIncorrectPassword} class="password-fields">
      <label for="password" class="password_label">Password</label>
      <input type="password" name="password" id ="password" placeholder="Password" class="password-input" bind:value={$password}>
    </p>
    {#if enteredIncorrectPassword}
      <p class="active-forgot-password-link-container">
        <img src="https://d2ns0ol35p5dsi.cloudfront.net/images/mynursingmastery/icon-error.svg" alt="Error" class="icon-error-password">
        Password is incorrect. Trouble logging in? <a href="#/" class="forgot-password-link" on:click|preventDefault={() => state.set(STATES.FORGOT_PASSWORD)} >Reset password</a>
      </p>
    {:else}
      <p class="forgot-password-link-container">
        <a href="#/" class="forgot-password-link" on:click|preventDefault={() => state.set(STATES.FORGOT_PASSWORD)} >Forgot password?</a>
      </p>
    {/if}
    <p class="submit-sign-in-fields">
      <input class="submit-sign-in" type="submit" value="Log In" on:click|preventDefault={signIn} />
    </p>
  </form>
  <Terms isModal={isModal} />
  {#if isModal}
    {#if $state === "sign-in"}
      <p class="account-cross-link">Don’t have an account? <a href="#/" class="create-account-link" on:click|preventDefault={() => state.set(STATES.SIGN_UP)} >Get started</a></p>
    {/if}
  {/if}
</span>
