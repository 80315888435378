<script>
  import { onMount } from 'svelte';

  import ExistingAccount from './existing_account.svelte';
  import ForgotPassword from './forgot_password.svelte';
  import SignIn from './sign_in.svelte';
  import SignUp from './sign_up.svelte';

  import { getQueryStringValue, isValueTrue } from "./lib/shared.js";
  import { initStore, STATES } from './lib/store.js';

  export let data;

  let activatorApp,
      appId,
      bundleIdentifier,
      isModal = false,
      ready = false,
      signInErrorMessage = "",
      signUpUrl = process.env.WEB_API_SIGN_UP_METHOD;

  const { email, password, referrerTag, state, name, code1, code2 } = initStore();

  onMount(async () => {
    referrerTag.set((data && data.referrer) || '');
    state.set((data && data.state) || STATES.SIGN_UP);
    isModal = isValueTrue(data && data.isModal);
    
    let appData = await fetchAppData(data.appId);
    
    if (getQueryStringValue("email")) {
      email.set(getQueryStringValue("email"));
    }
    if (getQueryStringValue("name")) {
      name.set(getQueryStringValue("name"));
    }
    if (getQueryStringValue("code1")) {
      code1.set(getQueryStringValue("code1"));
    }
    if (getQueryStringValue("code2")) {
      code2.set(getQueryStringValue("code2"));
    }
    bundleIdentifier = appData.bundle_identifier;
    appId = appData.id;
    
    if (isValueTrue(data.activatorsEnabled)) {
      activatorApp = appData.activator;
    }

    ready = true;
  });

  async function fetchAppData(app_id) {

    let response = await fetch(process.env.MARKETING_API + "/api/apps?id=" + app_id);
    return await response.json();
  }

</script>

<style>
</style>

{#if ready}
  <span class="user-portal">
    {#if $state === STATES.SIGN_IN}
      <SignIn 
        appId={appId} 
        bundleIdentifier={bundleIdentifier} 
        email={email}
        code1={code1}
        code2={code2}
        isModal={isModal}
        password={password}
        state={state}
      />
    {:else if $state === STATES.SIGN_UP}
      <SignUp 
        activatorApp={activatorApp}
        appId={appId} 
        bundleIdentifier={bundleIdentifier} 
        email={email}
        firstName={name}
        code1={code1}
        code2={code2}
        isModal={isModal} 
        password={password}
        referrerTag={referrerTag}
        state={state}
      />
    {:else if $state === STATES.EXISTING_ACCOUNT}
      <ExistingAccount
        email={email}
        state={state}
      />
    {:else if $state === STATES.FORGOT_PASSWORD || $state === STATES.FORGOT_PASSWORD_SUCCESS } 
      <ForgotPassword
        bundleIdentifier={bundleIdentifier}
        email={email}
        state={state}
      />
    {/if}
  </span>
{/if}
